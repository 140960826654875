// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-app-js": () => import("../src/App.js" /* webpackChunkName: "component---src-app-js" */),
  "component---src-page-templates-blog-editor-page-js": () => import("../src/PageTemplates/BlogEditorPage.js" /* webpackChunkName: "component---src-page-templates-blog-editor-page-js" */),
  "component---src-page-templates-blog-viewer-page-js": () => import("../src/PageTemplates/BlogViewerPage.js" /* webpackChunkName: "component---src-page-templates-blog-viewer-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

